<template>
  <footer
    class="d-flex justify-content-between align-items-center bg-dark text-white p-2"
  >
    <div class="flex-grow-1 text-center">
      <small>Happy Jungle &copy; 2024</small>
    </div>
    <div class="text-muted" style="flex-grow: 0; opacity: 0.3">
      <small>{{ getVersionNumber() }}</small>
    </div>
  </footer>
</template>

<script>
import { VERSION_APP } from '@/constants'

export default {
  name: 'Footer',
  methods: {
    getVersionNumber() {
      return VERSION_APP.VERSION // Acessando diretamente a propriedade 'VERSION'
    }
  }
}
</script>

<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <b-row>
      <b-col sm="12" md="6" lg="6">
        <b-list-group>
          <b-list-group-item :to="{ name: 'ToysIndex' }">
            <span class="fas fa-truck mr-2" />Brinquedos
          </b-list-group-item>

          <b-list-group-item :to="{ name: 'StoresIndex' }">
            <span class="fas fa-university mr-2" />Lojas
          </b-list-group-item>

          <b-list-group-item :to="{ name: 'PeriodsIndex' }">
            <span class="fas fa-clock mr-2" /> Períodos
          </b-list-group-item>

          <b-list-group-item :to="{ name: 'ProductsIndex' }">
            <span class="fas fa-shopping-cart mr-2" />Produtos
          </b-list-group-item>

          <b-list-group-item :to="{ name: 'UsersIndex' }">
            <span class="fas fa-users mr-2" />Usuários
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </Layout>
</template>

<script>
import Layout from './Layout'

export default {
  name: 'RegistersIndexView',
  components: {
    Layout
  },
  computed: {
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Cadastros',
        to: { name: 'RegistersIndex' },
        active: true
      }
    ]
  }
}
</script>
